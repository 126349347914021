<template>
  <div id="page-lap-pelayanan-harian">
    <b-container fluid>
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-card>
            <!-- <h1>DALAM PENGEMBANGAN</h1> -->
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Dari
                  </template>
                  <DatePicker
                    style="width: 100%"
                    id="tanggal"
                    format="MMMM - YYYY"
                    type="month"
                    v-model="dataSearch.tanggal_awal"
                    @change="getSearch()"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sampai
                  </template>
                  <DatePicker
                    style="width: 100%"
                    id="tanggal"
                    format="MMMM - YYYY"
                    type="month"
                    v-model="dataSearch.tanggal_akhir"
                    @change="getSearch()"
                  ></DatePicker>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="width:100%;display:flex">
                  <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                    <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                  </div>
                  <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                    <b-button variant="outline-primary" size="sm">
                      <b-form-checkbox
                        id="check_puskesmas"
                        name="check_puskesmas"
                        v-model="checkedPuskesmas"
                        @change="toggleSelectPuskesmas()"
                      >
                        <span class="pt-1">Pilih Semua</span>
                      </b-form-checkbox>
                    </b-button>
                  </div>
                </div>
                <hr>
                <b-row style="padding-left:7.5px;margin-top:15px;">
                  <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                      <b-form-checkbox
                        v-model="item.checked"
                      >
                        {{ item.nama_puskesmas }}
                      </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-row>
            <b-row class="mt-3">
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div class="d-flex">
                  <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  <b-button v-if="onUpdate" @click="$bvModal.show('modal-update-infeksi-menular-seksual')" variant="warning" class="mr-2">Update</b-button>
                  <b-button v-else @click="$bvModal.show('modal-update-infeksi-menular-seksual')" variant="primary" class="mr-2">Buat Baru</b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <h4 style="text-align:center"><strong>LAPORAN BULANAN - KONSELING HIV</strong></h4>
                <!-- <h4 style="text-align:center"><strong>LAPORAN BULANAN - KTS/VCT DAN TIPK</strong></h4> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="6" lg="6">
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Puskesmas Pembantu yang ada
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_pustu"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Poskesdes/Bidan di Desa
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_poskesdes"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="6" lg="6">
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Puskesmas Pembantu yang lapor
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_pustu_lapor"
                    disabled
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-md="7">
                  <template v-slot:label>
                    Jumlah Poskesdes/Bidan yang lapor
                  </template>
                  <b-form-input 
                    type="number" 
                    v-model="dataForm.jumlah_poskesdes_lapor"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <h6 style="font-weight: 700; color: #9c4098; font-size: 16px;">
                  Konseling dan Tes HIV Sukarela (KTS/VCT)
                </h6>
                <b-table-simple responsive class="table-lap-pelayanan-harian">
                  <b-thead>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                      <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="dataForm.data_kts">
                    <b-tr v-for="(value, key, i) in is_real_kts" :key="key">
                      <b-td class="text-center">{{ i+1 }}</b-td>
                      <b-td>{{ key }}</b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real_kts[key].laki_laki" :key="'01'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_kts[key].laki_laki.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_kts[key].laki_laki[key2]}}</div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real_kts[key].perempuan" :key="'02'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_kts[key].perempuan.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_kts[key].perempuan[key2]}}</div>
                      </b-td>
                      <b-td class="text-center"><b>{{ dataForm.data_kts[key].total_lp }}</b></b-td>
                    </b-tr>
                    <b-tr v-if="total_kts">
                      <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total_kts.laki_laki" :key="'01'+key2">
                        <div><b>{{ total_kts.laki_laki[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total_kts.perempuan" :key="'02'+key2">
                        <div><b>{{ total_kts.perempuan[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center"><b>{{ total_kts.total_lp }}</b></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <h6 style="font-weight: 700; color: #9c4098; font-size: 16px;">
                  Konseling dan Tes HIV Atas Inisiasi Petugas Kesehatan (TIPK)
                </h6>
                <b-table-simple responsive class="table-lap-pelayanan-harian">
                  <b-thead>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                      <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                      <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                      <b-th colspan="14" style="min-width: 100px;">Penyakit Terkait Pasien	</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">TB</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Dermatitis</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Sifilis</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Herpes</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Hepatitis</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Diare</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">LGVs</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Toksoplasmosis</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Kandidiasis</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">PCP</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Wasting.S</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">IMS</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Lainnya</b-th>
                      <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="dataForm.data_tipk">
                    <b-tr v-for="(value, key, i) in is_real_tipk" :key="key">
                      <b-td class="text-center">{{ i+1 }}</b-td>
                      <b-td>{{ key }}</b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real_tipk[key].laki_laki" :key="'01'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_tipk[key].laki_laki.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_tipk[key].laki_laki[key2]}}</div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real_tipk[key].perempuan" :key="'02'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_tipk[key].perempuan.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_tipk[key].perempuan[key2]}}</div>
                      </b-td>
                      <b-td class="text-center"><b>{{ dataForm.data_tipk[key].total_lp }}</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in is_real_tipk[key].penyakit_terkait_pasien" :key="'03'+key2">
                        <div v-if="key2 == 'jumlah'"><b>{{ dataForm.data_tipk[key].penyakit_terkait_pasien.jumlah }}</b></div>
                        <div v-else type="number">{{dataForm.data_tipk[key].penyakit_terkait_pasien[key2]}}</div>
                      </b-td>
                    </b-tr>
                    <b-tr v-if="total_tipk">
                      <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total_tipk.laki_laki" :key="'01'+key2">
                        <div><b>{{ total_tipk.laki_laki[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center" v-for="(value2, key2) in total_tipk.perempuan" :key="'02'+key2">
                        <div><b>{{ total_tipk.perempuan[key2] }}</b></div>
                      </b-td>
                      <b-td class="text-center"><b>{{ total_tipk.total_lp }}</b></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <ModalForm
      :onUpdate="onUpdate"
      :dataSearch="dataSearch"
      :is_data="dataForm"
      :is_real="is_real_kts"
      :is_real_kts="is_real_kts"
      :is_real_tipk="is_real_tipk"
      @alertFromChild="triggerAlert($event), getSearch()"
    />
  </div>
</template>
  
<script>
import ModalForm from "./modal_form.vue";
export default {
  components: {
    ModalForm,
  },
  name: "kunjungan_pasien",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      dataSearch: {
        tanggal_awal: null,
        tanggal_akhir: null,
      },
      listRujukInternal: [],
      kosong: false,
      dataForm: {
        tanggal: this.tanggal_sesi,
        bulan_konseling_hiv: null,
        tahun_konseling_hiv: null,
        jumlah_pustu: 0,
        jumlah_poskesdes: 0,
        jumlah_pustu_lapor: 0,
        jumlah_poskesdes_lapor: 0,
        data_kts: {},
        data_tipk: {},
      },
      is_real_kts: {},
      is_real_tipk: {},
      total : {},
      total_kts : {},
      total_tipk : {},
      listPuskesmas: [],
      checkedPuskesmas: false,
      items: [],
      filter: null,
      tableBusy: false,
      disabled_field: false,
    };
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
    onUpdate(){
      return this.$moment(this.dataSearch.tanggal_awal).format('MM-YYYY') == this.$moment(this.dataSearch.tanggal_akhir).format('MM-YYYY') && !this.kosong
    }
  },
  async activated(){
    const vm = this
    await vm.createKts()
    await vm.createTipk()
    vm.getData()
    vm.reset()
  },
  methods: {
    async createKts(){
      const vm = this
      vm.dataForm.tanggal = new Date(vm.tanggal_sesi)
      let head = {
        'Jumlah orang yang berkunjung bulan ini': {},
        'Jumlah orang yang baru berkunjung bulan ini': {},
        'Jumlah orang yang baru berkunjung bulan ini yang dirujuk oleh LSM': {},
        'Jumlah orang yang diberi pra tes konseling': {},
        'Jumlah orang yang dites HIV': {},
        'Jumlah orang yang diberi pasca tes konseling dan menerima hasil': {},
        'Jumlah orang yang HIV positif': {},
        'Jumlah ibu hamil yang dites HIV': {},
        'Jumlah ibu hamil yang dites HIV dan menerima hasil': {},
        'Jumlah ibu hamil yang HIV positif dan menerima hasil': {},
        'Jumlah orang yang HIV positif dirujuk ke PDP (CST)': {},
        'Jumlah orang yang HIV positif dirujuk ke petugas pendukung ( staf LSM, manajer kasus, kader)': {},
        'Jumlah bumil HIV positif yang dirujuk ke PDP dan PPIA': {},
        'Jumlah orang HIV positif dikaji gejala TB': {},
        'Jumlah kondom yang diberikan ke klien': {},
        'Jumlah orang yang diberi pasca tes konseling dan menerima hasil yang dirujuk oleh LSM': {},
      }
      const item = {
        'laki_laki': {
          '1': 0,
          '1_14': 0,
          '15_19': 0,
          '20_24': 0,
          '25_49': 0,
          '50': 0,
          jumlah: 0,
        },
        'perempuan': {
          '1': 0,
          '1_14': 0,
          '15_19': 0,
          '20_24': 0,
          '25_49': 0,
          '50': 0,
          jumlah: 0,
        },
        total_lp: 0
      }
      for (const sub in head) {
        head[sub] = item
      }
      // console.log('head kts', head)
      vm.is_real_kts = head
      vm.dataForm.data_kts = head
    },
    async createTipk(){
      const vm = this
      vm.dataForm.tanggal = new Date(vm.tanggal_sesi)
      let head = {
        'Jumlah orang yang ditawarkan tes HIV': {},
        'Jumlah orang yang di tes HIV': {},
        'Jumlah orang yang menerima hasil tes': {},
        'Jumlah orang yang HIV positif': {},
        'Jumlah orang yang dirujuk ke konseling lanjutan': {},
        'Jumlah orang yang HIV positif dirujuk ke PDP': {},
        'Jumlah orang yang HIV positif dikaji gejala TB': {},
        'Jumlah bumil yang ditawarkan tes HIV': {},
        'Jumlah ibu hamil yang dites HIV dan menerima hasil': {},
        'Jumlah ibu hamil yang HIV positif dan menerima hasil': {},
        'Jumlah bumil yang mengetahui status HIV pasangannya': {},
        'Jumlah bumil TB yang ditawarkan tes HIV': {},
        'Jumlah bumil TB yang dites HIV': {},
        'Jumlah bumil TB yang HIV Positif': {},
        'Jumlah bumil Sifilis yang ditawarkan tes HIV': {},
        'Jumlah bumil Sifilis yang dites HIV': {},
        'Jumlah bumil Sifilis yang HIV Positif': {},
        'Jumlah bumil HIV positif yang dirujuk ke PDP dan PPIA': {},
        'Jumlah kondom yang diberikan ke klien': {},
      }
      const item = {
        'laki_laki': {
          '1': 0,
          '1_14': 0,
          '15_19': 0,
          '20_24': 0,
          '25_49': 0,
          '50': 0,
          jumlah: 0,
        },
        'perempuan': {
          '1': 0,
          '1_14': 0,
          '15_19': 0,
          '20_24': 0,
          '25_49': 0,
          '50': 0,
          jumlah: 0,
        },
        'penyakit_terkait_pasien': {
          tb: 0,
          dermatitis: 0,
          herpes: 0,
          sifilis: 0,
          hepatitis: 0,
          diare: 0,
          lgvs: 0,
          toksoplasmosis: 0,
          kandidisiasis: 0,
          pcp: 0,
          wasting_s: 0,
          ims: 0,
          lainya: 0,
          jumlah: 0,
        },
        total_lp: 0
      }
      for (const sub in head) {
        head[sub] = item
      }
      // console.log('head tipk', head)
      vm.is_real_tipk = head
      vm.dataForm.data_tipk = head
    },
    // menghitung column / kolom
    async countTotalKts(){
      const vm = this
      const total = {}
      const data = JSON.parse(JSON.stringify(vm.dataForm.data_kts))
      for (const key2 in data) {
        for (const key3 in data[key2]) {
          if(total[key3] === undefined) {
            if(key3 == 'total_lp'){
              total[key3] = 0
            }else{
              total[key3] = {}
            }
          }
          if(key3 == 'total_lp'){
            if(total[key3] === undefined || total[key3] == {}) {
              total[key3] = Number.parseInt(data[key2][key3])
            }else{
              total[key3] += Number.parseInt(data[key2][key3])
            }
          }else{
            for (const key4 in data[key2][key3]) {
              if(!total[key3][key4]) {
                total[key3][key4] = Number.parseInt(data[key2][key3][key4])
              }else{
                total[key3][key4] += Number.parseInt(data[key2][key3][key4])
              }
            }
          }
        }
      } 
      vm.total_kts = total
      // console.log('countTotalKts', total)
    },
    async countTotalTipk(){
      const vm = this
      const total = {}
      const data = JSON.parse(JSON.stringify(vm.dataForm.data_tipk))
      for (const key2 in data) {
        for (const key3 in data[key2]) {
          if(total[key3] === undefined) {
            if(key3 == 'total_lp'){
              total[key3] = 0
            }else{
              total[key3] = {}
            }
          }
          if(key3 == 'total_lp'){
            if(total[key3] === undefined || total[key3] == {}) {
              total[key3] = Number.parseInt(data[key2][key3])
            }else{
              total[key3] += Number.parseInt(data[key2][key3])
            }
          }else{
            for (const key4 in data[key2][key3]) {
              if(!total[key3][key4]) {
                total[key3][key4] = Number.parseInt(data[key2][key3][key4])
              }else{
                total[key3][key4] += Number.parseInt(data[key2][key3][key4])
              }
            }
          }
        }
      } 
      vm.total_tipk = total
      // console.log('total_tipk', total)
    },
    async toggleSelectPuskesmas () {
      const vm = this
      vm.listPuskesmas.forEach(function (data) {
        data.checked = vm.checkedPuskesmas;
      });
      vm.$forceUpdate()
    },
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        //puskesmas
        vm.dataForm.tanggal = null
        const bulk_puskesmas_id = []
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const puskesmas = vm.listPuskesmas[i];
          if(puskesmas.checked){
            bulk_puskesmas_id.push(puskesmas.id)
          }
        }
        const res = await vm.$axios.post('/konseling_hiv/laporan_konseling_hiv', {
          dari_bulan: vm.$moment(vm.dataSearch.tanggal_awal).format('MM'),
          dari_tahun: vm.$moment(vm.dataSearch.tanggal_awal).format('YYYY'),
          sampai_bulan: vm.$moment(vm.dataSearch.tanggal_akhir).format('MM'),
          sampai_tahun: vm.$moment(vm.dataSearch.tanggal_akhir).format('YYYY'),
          bulk_puskesmas_id,
        })
        // console.log('konseling_hiv/laporan_konseling_hiv', res)
        if(res.data.status == 200 && res.data.data){
          const x = res.data.data
          x.data_kts = x.kts
          x.data_tipk = x.tipk
          x.kts = undefined
          x.tipk = undefined
          vm.dataForm = x
          vm.countTotalKts()
          vm.countTotalTipk()
          // console.log('vm.dataForm', vm.dataForm)
          vm.kosong = false
          if(vm.onUpdate){
            vm.dataForm.tanggal = new Date(vm.dataSearch.tanggal_awal)
          }
        } else {
          vm.triggerAlert({ variant: "success", msg: res.data.message, showing: true });
          vm.dataForm = {
            tanggal: this.tanggal_sesi,
            bulan_konseling_hiv: null,
            tahun_konseling_hiv: null,
            jumlah_pustu: 0,
            jumlah_poskesdes: 0,
            jumlah_pustu_lapor: 0,
            jumlah_poskesdes_lapor: 0,
            data_kts: vm.is_real_kts,
            data_tipk: vm.is_real_tipk,
          }
          vm.kosong = true
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.dataSearch = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi)),
        tanggal_akhir: new Date(vm.$moment(vm.$store.state.tanggal_sesi)),
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .table-lap-pelayanan-harian th{ */
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
