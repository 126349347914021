<template>
    <div>
      <b-modal
        id="modal-update-infeksi-menular-seksual"
        size="xl"
        centered
        title="Update Infeksi Menular Seksual (IMS)"
        header-bg-variant="primary"
        header-text-variant="light"
        @show="openModal()"
      >
        <div class="">
          <b-row class="m-0">
            <b-col cols="12" md="12" lg="12">
              <b-form-group label-cols-md="4">
                <template v-slot:label>
                  Bulan - Tahun
                </template>
                <date-picker
                  style="width: 100%"
                  id="tanggal"
                  format="MMMM - YYYY"
                  type="month"
                  v-model="dataForm.tanggal"
                  :disabled="onUpdate"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6" lg="6">
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Puskesmas Pembantu yang ada
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_pustu"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Poskesdes/Bidan di Desa
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_poskesdes"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" md="6" lg="6">
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Puskesmas Pembantu yang lapor
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_pustu_lapor"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-cols-md="8">
                <template v-slot:label>
                  Jumlah Poskesdes/Bidan yang lapor
                </template>
                <b-form-input 
                  type="number" 
                  v-model="dataForm.jumlah_poskesdes_lapor"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <h6 style="font-weight: 700; color: #9c4098; font-size: 16px;">
                Konseling dan Tes HIV Sukarela (KTS/VCT)
              </h6>
              <b-table-simple responsive class="table-lap-pelayanan-harian">
                <b-thead>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                    <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                  </b-tr>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                  </b-tr>
                </b-thead>
                
                <b-tbody v-if="dataForm.data_kts">
                  <b-tr v-for="(value, key, i) in is_real_kts" :key="key">
                    <b-td class="text-center">{{ i+1 }}</b-td>
                    <b-td>{{ key }}</b-td>
                    <b-td v-for="(value2, key2) in is_real_kts[key].laki_laki" :key="'01'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_kts[key].laki_laki.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_kts[key].laki_laki[key2]" @input="countDataKts()"></b-form-input>
                    </b-td>
                    <b-td v-for="(value2, key2) in is_real_kts[key].perempuan" :key="'02'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_kts[key].perempuan.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_kts[key].perempuan[key2]" @input="countDataKts()"></b-form-input>
                    </b-td>
                    <b-td class="text-center"><b>{{ dataForm.data_kts[key].total_lp }}</b></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                    <b-td v-for="(value2, key2) in total_kts.laki_laki" :key="'01'+key2">
                      <div class="text-center"><b>{{ total_kts.laki_laki[key2] }}</b></div>
                    </b-td>
                    <b-td v-for="(value2, key2) in total_kts.perempuan" :key="'02'+key2">
                      <div class="text-center"><b>{{ total_kts.perempuan[key2] }}</b></div>
                    </b-td>
                    <b-td class="text-center"><b>{{ total_kts.total_lp }}</b></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <h6 style="font-weight: 700; color: #9c4098; font-size: 16px;">
                Konseling dan Tes HIV Atas Inisiasi Petugas Kesehatan (TIPK)
              </h6>
              <b-table-simple responsive class="table-lap-pelayanan-harian">
                <b-thead>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 30px;">No</b-th>
                    <b-th rowspan="2" style="min-width: 200px;">Nama</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Laki - Laki	</b-th>
                    <b-th colspan="7" style="min-width: 100px;">Perempuan	</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Total L/P</b-th>
                    <b-th colspan="14" style="min-width: 100px;">Penyakit Terkait Pasien</b-th>
                  </b-tr>
                  <b-tr class="text-center">
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&lt; 1</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">1 - 14</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">15 - 19</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">20 - 24</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">25 - 49</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">&gt;= 50</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">TB</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Dermatitis</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Sifilis</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Herpes</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Hepatitis</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Diare</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">LGVs</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Toksoplasmosis</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Kandidiasis</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">PCP</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Wasting.S</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">IMS</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Lainnya</b-th>
                    <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody v-if="dataForm.data_tipk">
                  <b-tr v-for="(value, key, i) in is_real_tipk" :key="key">
                    <b-td class="text-center">{{ i+1 }}</b-td>
                    <b-td>{{ key }}</b-td>
                    <b-td v-for="(value2, key2) in is_real_tipk[key].laki_laki" :key="'01'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_tipk[key].laki_laki.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_tipk[key].laki_laki[key2]" @input="countDataTipk()"></b-form-input>
                    </b-td>
                    <b-td v-for="(value2, key2) in is_real_tipk[key].perempuan" :key="'02'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_tipk[key].perempuan.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_tipk[key].perempuan[key2]" @input="countDataTipk()"></b-form-input>
                    </b-td>
                    <b-td class="text-center"><b>{{ dataForm.data_tipk[key].total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in is_real_tipk[key].penyakit_terkait_pasien" :key="'03'+key2">
                      <div v-if="key2 == 'jumlah'" class="text-center"><b>{{ dataForm.data_tipk[key].penyakit_terkait_pasien.jumlah }}</b></div>
                      <b-form-input v-else type="number" v-model="dataForm.data_tipk[key].penyakit_terkait_pasien[key2]" @input="countDataTipk()"></b-form-input>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="text-center"><b>Total</b></b-td>
                    <b-td v-for="(value2, key2) in total_tipk.laki_laki" :key="'01'+key2">
                      <div class="text-center"><b>{{ total_tipk.laki_laki[key2] }}</b></div>
                    </b-td>
                    <b-td v-for="(value2, key2) in total_tipk.perempuan" :key="'02'+key2">
                      <div class="text-center"><b>{{ total_tipk.perempuan[key2] }}</b></div>
                    </b-td>
                    <b-td class="text-center"><b>{{ total_tipk.total_lp }}</b></b-td>
                    <b-td v-for="(value2, key2) in total_tipk.kelompok_resiko" :key="'03'+key2">
                      <div class="text-center"><b>{{ total_tipk.kelompok_resiko[key2] }}</b></div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-update-infeksi-menular-seksual')">
            Batal
          </b-button>
          <b-button
            v-if="onUpdate"
            variant="warning"
            @click="update()"
            >Update</b-button
          >
          <b-button
            v-else
            variant="primary"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
  
<script>
  export default {
    name: "modalInput",
    props: [
      'is_data', 
      'is_real_kts', 
      'is_real_tipk', 
      'onUpdate', 
      'dataSearch'
    ],
    data() {
      return {
        dataForm: {},
        total: {},
        total_kts: {},
        total_tipk: {},
        busy: true,
        button: "Simpan",
      };
    },
    watch: {
    },
    methods: {
      async openModal(){
        const vm = this
        vm.busy = true
        // await new Promise(resolve => setTimeout(resolve, 1000));
        await new Promise(resolve => setTimeout(resolve, 10));
        const dataForm = JSON.parse(JSON.stringify(vm.is_data))
        dataForm.tanggal = new Date(vm.dataSearch.tanggal_awal)
        if(!vm.onUpdate){
          dataForm.data_kts = vm.is_real_kts
          dataForm.data_tipk = vm.is_real_tipk
        }
        vm.dataForm = dataForm
        // console.log(vm.is_data)
        vm.countDataKts()
        vm.countDataTipk()
        vm.reset()
        vm.busy = false
      },
      // menghitung row / baris
      async countDataKts(){
        const vm = this
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_kts))
        for (const key2 in data) {
          let total_lp = 0 // total nilai dari laki laki dan perempuan per row / baris
          for (const key3 in data[key2]) {
            if(key3 != 'total_lp'){
              // menghitung nilai dari laki laki / perempuan / kelompok resiko per row / baris
              let jumlah = 0
              for (const key4 in data[key2][key3]) {
                data[key2][key3][key4] = Number.parseInt(data[key2][key3][key4])
                if(key4 != 'jumlah'){
                  jumlah +=  data[key2][key3][key4]
                }
              }
              data[key2][key3].jumlah = jumlah // masukkan nilai dari laki laki / perempuan / kelompok resiko per row / baris
              // menghitung total nilai dari laki laki dan perempuan resiko per row / baris
              if(key3 == 'laki_laki' || key3 == 'perempuan'){
                total_lp += jumlah
              }
            }
          }
          data[key2].total_lp = total_lp // masukkan total nilai dari laki laki dan perempuan resiko per row / baris
          }
        vm.dataForm.data_kts =  JSON.parse(JSON.stringify(data))
        vm.countTotalKts()
      },
      async countDataTipk(){
        const vm = this
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_tipk))
        for (const key2 in data) {
          let total_lp = 0 // total nilai dari laki laki dan perempuan per row / baris
          for (const key3 in data[key2]) {
            if(key3 != 'total_lp'){
              // menghitung nilai dari laki laki / perempuan / kelompok resiko per row / baris
              let jumlah = 0
              for (const key4 in data[key2][key3]) {
                data[key2][key3][key4] = Number.parseInt(data[key2][key3][key4])
                if(key4 != 'jumlah'){
                  jumlah +=  data[key2][key3][key4]
                }
              }
              data[key2][key3].jumlah = jumlah // masukkan nilai dari laki laki / perempuan / kelompok resiko per row / baris
              // menghitung total nilai dari laki laki dan perempuan resiko per row / baris
              if(key3 == 'laki_laki' || key3 == 'perempuan'){
                total_lp += jumlah
              }
            }
          }
          data[key2].total_lp = total_lp // masukkan total nilai dari laki laki dan perempuan resiko per row / baris
          }
        vm.dataForm.data_tipk =  JSON.parse(JSON.stringify(data))
        vm.countTotalTipk()
      },
      // menghitung column / kolom
      async countTotalKts(){
        const vm = this
        const total = {}
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_kts))
        for (const key2 in data) {
          for (const key3 in data[key2]) {
            if(total[key3] === undefined) {
              if(key3 == 'total_lp'){
                total[key3] = 0
              }else{
                total[key3] = {}
              }
            }
            if(key3 == 'total_lp'){
              if(total[key3] === undefined || total[key3] == {}) {
                total[key3] = Number.parseInt(data[key2][key3])
              }else{
                total[key3] += Number.parseInt(data[key2][key3])
              }
            }else{
              for (const key4 in data[key2][key3]) {
                if(!total[key3][key4]) {
                  total[key3][key4] = Number.parseInt(data[key2][key3][key4])
                }else{
                  total[key3][key4] += Number.parseInt(data[key2][key3][key4])
                }
              }
            }
          }
        } 
        vm.total_kts = total
        // console.log('countTotalKts', total)
      },
      async countTotalTipk(){
        const vm = this
        const total = {}
        const data = JSON.parse(JSON.stringify(vm.dataForm.data_tipk))
        for (const key2 in data) {
          for (const key3 in data[key2]) {
            if(total[key3] === undefined) {
              if(key3 == 'total_lp'){
                total[key3] = 0
              }else{
                total[key3] = {}
              }
            }
            if(key3 == 'total_lp'){
              if(total[key3] === undefined || total[key3] == {}) {
                total[key3] = Number.parseInt(data[key2][key3])
              }else{
                total[key3] += Number.parseInt(data[key2][key3])
              }
            }else{
              for (const key4 in data[key2][key3]) {
                if(!total[key3][key4]) {
                  total[key3][key4] = Number.parseInt(data[key2][key3][key4])
                }else{
                  total[key3][key4] += Number.parseInt(data[key2][key3][key4])
                }
              }
            }
          }
        } 
        vm.total_tipk = total
        // console.log('total_tipk', total)
      },
      async simpan(){
        const vm = this
        vm.busy = true
        try {
          //ubah value menjadi number
          let data_kts = JSON.parse(JSON.stringify(vm.dataForm.data_kts))
          for (const key1 in data_kts) {
            for (const key2 in data_kts[key1]) {
              for (const key3 in data_kts[key1][key2]) {
                for (const key4 in data_kts[key1][key2][key3]) {
                  data_kts[key1][key2][key3][key4] = Number.parseInt(data_kts[key1][key2][key3][key4])
                }
              }
            }
          }
          let data_tipk = JSON.parse(JSON.stringify(vm.dataForm.data_tipk))
          for (const key1 in data_tipk) {
            for (const key2 in data_tipk[key1]) {
              for (const key3 in data_tipk[key1][key2]) {
                for (const key4 in data_tipk[key1][key2][key3]) {
                  data_tipk[key1][key2][key3][key4] = Number.parseInt(data_tipk[key1][key2][key3][key4])
                }
              }
            }
          }
          // console.log({
          //   ...vm.dataForm,
          //   bulan_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('MM'),
          //   tahun_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('YYYY'),
          //   kts: data_kts,
          //   tipk: data_tipk,
          // })
          let res = await vm.$axios.post('/konseling_hiv/register', {
            ...vm.dataForm,
            bulan_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('MM'),
            tahun_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('YYYY'),
            kts: data_kts,
            tipk: data_tipk,
            data_kts: undefined,
            data_tipk: undefined,
          })
          // console.log('register kegiatan_kelompok', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Konseling HIV', showing: true });
            vm.$bvModal.hide('modal-update-infeksi-menular-seksual')
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      async update(){
        const vm = this
        vm.busy = true
        try {
          //ubah value menjadi number
          let data_kts = JSON.parse(JSON.stringify(vm.dataForm.data_kts))
          for (const key1 in data_kts) {
            for (const key2 in data_kts[key1]) {
              for (const key3 in data_kts[key1][key2]) {
                for (const key4 in data_kts[key1][key2][key3]) {
                  data_kts[key1][key2][key3][key4] = Number.parseInt(data_kts[key1][key2][key3][key4])
                }
              }
            }
          }
          let data_tipk = JSON.parse(JSON.stringify(vm.dataForm.data_tipk))
          for (const key1 in data_tipk) {
            for (const key2 in data_tipk[key1]) {
              for (const key3 in data_tipk[key1][key2]) {
                for (const key4 in data_tipk[key1][key2][key3]) {
                  data_tipk[key1][key2][key3][key4] = Number.parseInt(data_tipk[key1][key2][key3][key4])
                }
              }
            }
          }
          let res = await vm.$axios.post('/konseling_hiv/update', {
            ...vm.dataForm,
            bulan_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('MM'),
            tahun_konseling_hiv: vm.$moment(vm.dataForm.tanggal).format('YYYY'),
            kts: data_kts,
            tipk: data_tipk,
            data_kts: undefined,
            data_tipk: undefined,
          })
          // console.log('update kegiatan_kelompok', res)
          if(res.data.status == 200){
            vm.$emit("alertFromChild", { variant: "success", msg: 'Berhasil Membuat Konseling HIV', showing: true });
            vm.$bvModal.hide('modal-update-infeksi-menular-seksual')
          }else{
            vm.$emit("alertFromChild", { variant: "danger", msg: res.data.message, showing: true });
          }
        } catch (error) {
          console.log(error)
          if (error.message) {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.message.toUpperCase(), showing: true });
          } else {
            vm.$emit("alertFromChild", { variant: "danger", msg: error.toUpperCase(), showing: true });
          }
        } finally {
          vm.busy = false
        }
      },
      reset() {
        const vm = this
        vm.data = {
          // edu_id: null,
          kode_club: null,
          tanggal_pelayanan: vm.tanggal_sesi,
          kode_kegiatan: null,
          kode_kelompok: null,
          materi: null,
          pembicara: null,
          lokasi: null,
          keterangan: null,
          biaya: null,
        };
      },
    },
  };
</script>
  
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#modal-update-infeksi-menular-seksual .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>